import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Container from "../components/container";
import PhoneIcon from "../components/phoneIcon";

export default function ContactPage({ data: { pageData } }) {
  return (
    <Layout>
      <Container>
        <h1 className="text-3xl font-bold mb-4">{pageData.title}</h1>
        <p>Olga Cieślik SOS Kot i Pies</p>
        <a href="tel:604871738" className="flex mt-1">
          <PhoneIcon className="mr-1 w-6" />
          604 871 738
        </a>
      </Container>
    </Layout>
  );
}

export function Head() {
  return (
    <>
      <title>Olga Cieślik Kontakt</title>
      <meta
        name="description"
        content="Kontakt - Olga Cieślik Profesjonalny opiekun zwierząt"
      />
    </>
  );
}

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    pageData: datoCmsPage(slug: { eq: "kontakt" }) {
      title
      slug
      description
    }
  }
`;
